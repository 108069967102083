import { render, staticRenderFns } from "./vitransfer.vue?vue&type=template&id=31f8fe13&scoped=true"
import script from "./vitransfer.vue?vue&type=script&lang=js"
export * from "./vitransfer.vue?vue&type=script&lang=js"
import style0 from "./vitransfer.vue?vue&type=style&index=0&id=31f8fe13&prod&lang=css"
import style1 from "./vitransfer.vue?vue&type=style&index=1&id=31f8fe13&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31f8fe13",
  null
  
)

export default component.exports